import type { Price } from '@backmarket/http-api'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import {
  SCHEMA_BEST_RATING,
  SCHEMA_ITEM_CONDITION,
  SCHEMA_SEARCH_NAME,
  SCHEMA_WORST_RATING,
} from './constants'

/**
 * Format the JSON schema.org data to create the JSON LD scripts to inject
 */
export const prepareJsonLdScript = (o: object) => {
  return {
    innerHTML: JSON.stringify(o, null, 4),
    type: 'application/ld+json',
  }
}

/**
 * Builds the product schema object.
 * https://developers.google.com/search/docs/advanced/structured-data/product
 * This object is used in a JSON-LD script that is injected in the page head
 */
export const buildProductSchema = ({
  title,
  description,
  image,
  rate = undefined,
  itemCondition = SCHEMA_ITEM_CONDITION.REFURBISHED,
  price = undefined,
  priceRange = undefined,
  brand = '',
  isOutOfStock = false,
  companyName,
}: {
  title: string
  description: string
  image: string
  rate?: Rating
  itemCondition?: string
  price?: Price | null
  priceRange?: { minPrice: string; maxPrice: string; currency: string }
  brand?: string
  isOutOfStock: boolean
  companyName: string
}) => {
  const baseInfo = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: title,
    description,
    image,
  }

  const brandInfo = brand
    ? {
        brand: {
          '@type': 'Thing',
          name: brand,
        },
      }
    : {}

  const priceInfo = !isEmpty(price)
    ? {
        priceCurrency: price.currency,
        price: price.amount,
      }
    : {}

  const priceRangeInfo = !isEmpty(priceRange)
    ? {
        lowPrice: priceRange.minPrice,
        highPrice: priceRange.maxPrice,
        priceCurrency: priceRange.currency,
      }
    : {}

  const typeOffer = !isEmpty(priceRange) ? 'AggregateOffer' : 'Offer'

  const offersInfo = {
    offers: {
      '@type': typeOffer,
      availability: 'http://schema.org/InStock',
      itemCondition,
      ...priceInfo,
      ...priceRangeInfo,
      seller: {
        '@type': 'Organization',
        name: companyName,
      },
    },
  }

  const aggregateRatingInfo = !isEmpty(rate)
    ? {
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: rate.averageRate,
          reviewCount: rate.count,
          bestRating: SCHEMA_BEST_RATING,
          worstRating: SCHEMA_WORST_RATING,
        },
      }
    : {}

  return {
    ...baseInfo,
    ...brandInfo,
    ...(isOutOfStock ? {} : offersInfo),
    ...aggregateRatingInfo,
  }
}

/**
 * Builds the Sitelinks searchbox schema object.
 * https://developers.google.com/search/docs/advanced/structured-data/sitelinks-searchbox
 * This object is used in a JSON-LD script that is injected in the homepage head
 *
 * @param {string} homeUrl
 * @param {string} searchUrl
 *
 * @return {SchemaObject} Searchbox schema object
 */
export const buildSearchBoxSchema = ({
  homeUrl,
  searchUrl,
  companyName,
}: {
  homeUrl: string
  searchUrl: string
  companyName: string
}) => ({
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  name: companyName,
  url: homeUrl,
  potentialAction: [
    {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${searchUrl}?q={${SCHEMA_SEARCH_NAME}}`,
      },
      'query-input': `required name=${SCHEMA_SEARCH_NAME}`,
    },
  ],
})

/**
 * Builds the schema object for Faq.
 * https://developers.google.com/search/docs/advanced/structured-data/faqpage
 * This object is used in a JSON-LD script that is injected in the page head
 *
 * @typedef Question
 * @property {string} question
 * @property {string} answer
 *
 * @param {Question[]} questions
 *
 * @return {SchemaObject} Faq schema object
 */
export const buildFaqSchema = (
  questions: Array<{ question: string; answer: string }> = [],
) => {
  const items = questions.map((item) => {
    const { question, answer } = item

    return {
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    }
  })

  return {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: items,
  }
}

export type HomeSchemaTranslations = {
  schemaOrgaDescription: string
  schemaQuentinTitle: string
  schemaThibaudTitle: string
  schemaVianneyTitle: string
  schemaOrgaStreetAddress: string
  schemaOrgaAddressLocality: string
  schemaOrgaAddressRegion: string
  schemaOrgaPostalCode: string
  schemaOrgaAddressCountry: string
  schemaOrgaTelephone: string
}

export const buildHomeSchema = (
  homeUrl: string,
  {
    schemaOrgaDescription,
    schemaQuentinTitle,
    schemaThibaudTitle,
    schemaVianneyTitle,
    schemaOrgaStreetAddress,
    schemaOrgaAddressLocality,
    schemaOrgaAddressRegion,
    schemaOrgaPostalCode,
    schemaOrgaAddressCountry,
    schemaOrgaTelephone,
  }: HomeSchemaTranslations,
) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Back Market',
    url: homeUrl,
    logo: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/906aecd28464a3996c514d1b02a00983/SEO-Website-Thumbnail.png',
    description: schemaOrgaDescription,
    founder: [
      {
        '@type': 'Person',
        name: 'Thibaud Hug de Larauze',
        gender: 'Male',
        jobTitle: schemaThibaudTitle,
        image:
          'https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/enn8o0bkcrbmog0tbm98',
        sameAs: [
          'https://fr.linkedin.com/in/thibaud-hug-de-larauze-a9a9b160',
          'https://twitter.com/thibaudelarauze',
        ],
      },
      {
        '@type': 'Person',
        name: 'Quentin Le Brouster',
        gender: 'Male',
        jobTitle: schemaQuentinTitle,
        image:
          'https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/ymvblntd67xfvkgdua7i',
        sameAs: 'https://fr.linkedin.com/in/quentin-le-brouster-b1a02184',
      },
      {
        '@type': 'Person',
        name: 'Vianney Vaute',
        gender: 'Male',
        jobTitle: schemaVianneyTitle,
        image:
          'https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/fikykrgbg1s9whaynlru',
        sameAs: [
          'https://fr.linkedin.com/in/vianney-vaute-03ba2b62',
          'https://twitter.com/vinnyvandev',
        ],
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: schemaOrgaStreetAddress,
      addressLocality: schemaOrgaAddressLocality,
      addressRegion: schemaOrgaAddressRegion,
      postalCode: schemaOrgaPostalCode,
      addressCountry: schemaOrgaAddressCountry,
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'contact',
      telephone: schemaOrgaTelephone,
      email: 'press@backmarket.com',
    },
    sameAs: [
      'https://www.twitter.com/backmarket',
      'https://linkedin.com/company/back-market',
      'https://www.facebook.com/BackMarketCom',
      'https://www.instagram.com/backmarket',
      'https://play.google.com/store/apps/details?id=com.backmarket',
      'https://apps.apple.com/us/app/back-market-shop-online/id1458344336',
      'https://fr.wikipedia.org/wiki/Back_Market',
      'https://www.wikidata.org/wiki/Q56316472',
      'https://www.bbb.org/us/ny/brooklyn/profile/online-retailer/back-market-inc-0121-176814',
      'https://www.trustpilot.com/review/www.backmarket.com',
    ],
  }
}
